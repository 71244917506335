import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import './style.css';
import { OrgChartComponent } from './components/OrgChart';
import * as d3 from 'd3';

const App = props => {
  const [data, setData] = useState(null);
  let addNodeChildFunc = null;
  
  function onNodeClick(nodeId) {

    alert('clicked ' + nodeId);
  }

  useEffect(() => {
    /* Função que lê o arquivo JSON ou CSV, a variável que aponta para o nó pai precisa ter o nome parentId */
    /* Não é possível carregar um organograma com mais de uma raíz (mais de um superior geral, como um CEO, por exemplo) */
    /* Se o usuário não possuir um liderado, necessário deixar parentId vazio para CSV e parentId = null para JSON */
    // https://gist.githubusercontent.com/pedronunesweef/01e0a41ebb242b03ea518ab76eb105d1/raw/8cc80302af50e6cf1141d84b2c8e33a558523ff3/teste.json
    d3.json(
      'https://gist.githubusercontent.com/cleiton-weef/df07df48e64736cfff9a542d2e60303e/raw/c9d07910d41b0a6abb40bc6cdc32df8c9170ad98/agi-data.json'
    ).then(data => {
      setData(data);
    });
    
  }, [true]);
  return (
    <div>
      
      <OrgChartComponent
        setClick={click => (addNodeChildFunc = click)}
        onNodeClick={onNodeClick}
        data={data}
      />
    </div>
  );
};

render(<App />, document.getElementById('root'));
