import { ManagerSelect } from "../ManagerSelect";
import { Container, Avatar, Name, Email } from "./NodeContent.styles";

export const NodeContent = ({
  id,
  width,
  height,
  avatar,
  employeeName,
  employeeEmail,
  parentId,
  level,
  data,
}) => {
  return (
    <Container width={width} height={height}>
      <Avatar src={avatar} />
      <Name>{employeeName}</Name>
      <Email>{employeeEmail}</Email>
      {level > 1 && <ManagerSelect data={data} id={id} parentId={parentId} />}
    </Container>
  );
};
