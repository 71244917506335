export const ManagerSelect = ({ id, parentId, data }) => {
  return (
    <select className="manager-select" defaultValue={parentId}>
      <option value="">Selecione</option>
      {data.filter((person) => person.id !== id).map((person) => (
        <option value={person.id} key={person.id}>{person.name}</option>
      ))}
    </select>
  );
};
