import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
} from "@mui/material";


export const Container = styled(Stack)(() => ({
  fontFamily: "Inter",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  border: "1px solid #E4E2E9",
  padding: '20px',
  boxSizing: 'border-box',
}));


export const Avatar = styled('img')(() => ({
  marginTop: "-40px",
  width: "40px",
  height: "40px",
  borderRadius: '100px',
}));

export const Name = styled(Typography)(() => ({
  fontSize: "15px",
  color: "#08011E",
  marginTop: "12px",
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  height: '20px',
  boxSizing: 'border-box',
}));

export const Email = styled(Typography)(() => ({
  color: "#716E7B",
  marginTop: "3px",
  marginBottom: "8px",
  fontSize: "10px",
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  height: '13px',
  boxSizing: 'border-box',
}));
