import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { OrgChart } from '../../lib/d3-org-chart';
import { NodeContent } from '../NodeContent'
import ReactDOMServer from "react-dom/server";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as d3 from 'd3';


export const OrgChartComponent = (props, ref) => {
  const d3Container = useRef(null);
  const { data } = props;

  const fakeRootElement = { id: 0, parentId: '' };

  const transformedData = data?.map((item) => {
    if (!item.parentId) return { ...item, parentId: 0, avatar: item?.avatar?.replace(/pravatar\.cc\/[0-9]+/g, `pravatar.cc/40?img=${item.id % 70}`) };
    return { ...item, avatar: item?.avatar?.replace(/pravatar\.cc\/[0-9]+/g, `pravatar.cc/40?img=${item.id % 70}`) };
  }) || [];

  const rootNodesLength = transformedData?.filter((item) => item.parentId === 0)?.length;

  const dataWithFakeParentId = [fakeRootElement, ...transformedData];

  var chart;
  var index = 0;
  var compact = 0;
  var idUsuario = 0;
  var idGestor = 0;
  let activeNode;

  function SelectEmployee() {
    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel>Selecione um Colaborador</InputLabel>
        <Select
        >
          {
            data?.map((item) => (
              <MenuItem value={item.id} onClick={() => atribuirUsuario(item.name, item.id, item.avatar, item.email, item.area, item.role)}>
                <em>{item.name}</em>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }

  function SelectGestor() {
    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel>Selecione um Gestor</InputLabel>
        <Select
        >
          {
            data?.map((item) => (
              <MenuItem value={item.id} onClick={() => atribuirGestor(item.id)}>
                <em>{item.name}</em>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }


  /* Funções da biblioteca que ficam atreladas aos botões */
  function addNode(node) {
    chart.addNode(node);
  }

  function zoomOut() {
    chart.zoomOut();
  }

  function zoomIn() {
    chart.zoomIn();
  }

  function collapseAll() {
    chart.collapseAll(1);
  }

  function expandAll() {
    chart.expandAll();
  }

  function fit() {
    chart.fit();
  }

  function rotacionar() {
    chart.layout(["right", "bottom", "left", "top"][index++ % 4]).render().fit();
  }

  function compactar() {
    chart.compact(!!(compact++ % 2)).render().fit();
  }

  function marcarSuperior() {
    chart.clearHighlighting()
    chart.setUpToTheRootHighlighted(idUsuario).render().fit()
  }

  const handleSelectedPersonId = (id) => {
    setSelectedPersonId(id);
  }

  function atribuirUsuario(nome,id,avatar,area,email,role) {
    idUsuario = id;
    nomeUsuario=nome;
    avatarUsuario=avatar;
    areaUsuario=area;
    emailUsuario=email;
    roleUsuario=role;
  }

  function atribuirGestor(id) {
    idGestor = id;
  }

  function marcarUsuario() {
    chart.clearHighlighting()
    chart.setHighlighted(idUsuario).render().fit();
  }

  function limparMarcacao() {
    chart.clearHighlighting()
  }

  function centralizarUsuario() {
    chart.setCentered(idUsuario).render();
  }

  function updateManager() {
    chart.updateNodeParent(idUsuario, idGestor);
  }

  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(dataWithFakeParentId)
        /* Largura e altura dos nós */
        .nodeWidth(d => 300)
        .nodeHeight(d => d.depth === 1 ? 100 : 130)
        /* Margem entre filhos e entre irmãos, respectivamente */
        .childrenMargin(d => 100)
        .siblingsMargin((d) => 100)
        /* Margem entre irmãos na vertical e na horizontal, respectivamente */
        .compactMarginBetween((d) => 65)
        .compactMarginPair((d) => 100)
        /* Margem entre vizinhos */
        .neightbourMargin((a, b) => 100)
        /* Conteúdo que possui dentro do botão de expansão, componentizável e estilizável, sem essa função fica apenas uma seta como placeholder */
        .linkUpdate(function (d, i, arr) {
            if (i >= 0 && i < rootNodesLength) return;

            d3.select(this)
            .attr('stroke', (d) => d.depth > 0 && d.data._upToTheRootHighlighted ? '#152785' : 'lightgray')
            .attr('stroke-width', (d) => d.data._upToTheRootHighlighted ? 4 : 2);

            if (d.data._upToTheRootHighlighted) {
              d3.select(this).raise();
            }
        })
        .nodeUpdate(function (d, i, arr) {
          if (i === 0) return;

          d3.select(this)
          .attr('stroke', (d) => d.depth > 0 && d.data._upToTheRootHighlighted ? '#152785' : 'lightgray')
          .attr('stroke-width', (d) => d.data._upToTheRootHighlighted ? 12 : 0);

          d3.select(this)
            .select('.manager-select')
            .on('click', (event) => {
              event.stopPropagation();
            })
            .on('change', (event, node) => {
              const nodeId = node.data.id;
              const parentId = event.target.value;
              chart.clearHighlighting();
              chart.updateNodeParent(nodeId, parentId).render();
            })
      })
        .buttonContent(({ node, state }) => {
          if (node.depth == 0) return null;

          return `<div style="color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${node.children
            ? `<i class="fas fa-angle-up"></i>`
            : `<i class="fas fa-angle-down"></i>`
            }</span> ${node.data._directSubordinates}  </div>`;
        })
        /* Conteúdo de dentro do nó, componentizável e estilizável */
        .nodeContent(function (d, i, arr, state) {
          
          if (d.depth == 0) return null;

          return ReactDOMServer.renderToStaticMarkup(
            /* Componente do nó com os dados dele enviados como parâmetro */
            <NodeContent
              id={d.data.id}
              avatar={d.data.avatar}
              employeeName={d.data.name}
              employeeEmail={d.data.email}
              parentId={d.data.parentId}
              level={d.depth}
              data={data}
              width={d.width}
              height={d.height}
            />
            
          )

        })
        .render();

        chart.compact(!!(compact++ % 2)).render().fit();

        chart.onNodeClick(function (nodeId) {
          chart.clearHighlighting();

          if (nodeId !== activeNode) {
            const node = chart.getNodeById(nodeId);
            chart.collapseAllSiblings(node);
            chart.expandAllChildren(node);
            chart.setUpToTheRootHighlighted(nodeId).initialZoom(1).setCentered(nodeId).render();
          }

          activeNode = activeNode === nodeId ? null : nodeId;
        });
    }
  }, [props.data, d3Container.current]);

  return (

    <div>
      <div ref={d3Container} />
      <br />
      <div>
        <SelectEmployee />
      </div>
      <div>
        <button onClick={marcarUsuario}>
          Marcar Usuário
        </button>

        <button onClick={marcarSuperior}>
          Marcar Superiores
        </button>
      </div>
      <div>
        <SelectEmployee />
        <SelectGestor />
      </div>
      <div>
        <button onClick={zoomOut}>
          Zoom Out
        </button>
        <button onClick={zoomIn}>
          Zoom In
        </button>
        <button onClick={collapseAll}>
          Collapse All
        </button>
        <button onClick={expandAll}>
          Expand All
        </button>
        <button onClick={fit}>
          Fit
        </button>
        <button onClick={rotacionar}>
          Rotacionar
        </button>
        <button onClick={compactar}>
          Compactar
        </button>
        <button onClick={marcarSuperior}>
          Marcar Superior
        </button>
        <button onClick={limparMarcacao}>
          Limpar Marcação
        </button>
        <button onClick={centralizarUsuario}>
          Centralizar Usuário
        </button>
        <button onClick={updateManager}>
          Atualizar Gestor
        </button>


      </div>
    </div>
  );
};
